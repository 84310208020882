import { mapHelper } from "@/utils/common.js";

const gradeList = [
  {
    value: 1,
    label: "小学",
  },
  {
    value: 2,
    label: "初中",
  },
  {
    value: 3,
    label: "高中",
  },
];

const { map: gradeListMap, setOps: gradeListOps } = mapHelper.setMap(gradeList);

//
const classes = [
  {
    value: 1,
    label: "语文",
  },
  {
    value: 2,
    label: "数学",
  },
  {
    value: 3,
    label: "英语",
  },
];

const { map: classesMap, setOps: classesOps } = mapHelper.setMap(classes);

export { classesMap, classesOps, gradeListMap, classes, gradeList };
