let origin = "https://future.zhuneng.cn";
//章节列表
const getChapterListUrl = `/gateway/hc-edu/manageapi/cloudschool/chapterList`;
//章节详情
const getChapterDetailUrl = `/gateway/hc-edu/manageapi/cloudschool/chapterInfo`;
//章节添加|编辑
const addChapterUrl = `/gateway/hc-edu/manageapi/cloudschool/chapterUpdate`;
//章节删除
const deleteChapterUrl = `/gateway/hc-edu/manageapi/cloudschool/chapterDelete`;
//课程列表
const getCourseListUrl = `/gateway/hc-edu/manageapi/cloudschool/courseList`;
//课程详情
const getCourseDetailUrl = `/gateway/hc-edu/manageapi/cloudschool/courseInfo`;
//课程添加|编辑
const addCourseUrl = `/gateway/hc-edu/manageapi/cloudschool/courseUpdate`;
//课程删除
const deleteCourseUrl = `/gateway/hc-edu/manageapi/cloudschool/courseDelete`;
export {
  getChapterListUrl,
  getChapterDetailUrl,
  addChapterUrl,
  deleteChapterUrl,
  getCourseListUrl,
  getCourseDetailUrl,
  addCourseUrl,
  deleteCourseUrl,
};
