<template>
  <div class="courseList">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goback"></v-button>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          placeholder="请输入课程名称"
          label="课程名称"
          v-model="searchParam.title"
          :width="250"
        ></v-input>
        <v-input
          placeholder="老师"
          label="老师"
          v-model="searchParam.teacher"
          :width="250"
        ></v-input>
        <v-input
          placeholder="学校"
          label="学校"
          v-model="searchParam.school"
          :width="250"
        ></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getCourseListUrl, deleteCourseUrl } from "./api.js";

import {
  classesMap,
  classesOps,
  gradeListMap,
  classes,
  gradeList,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    let _this = this;
    return {
      classes,
      gradeList,
      searchParam: {
        title: "",
        teacher: "",
        school: "",
        gradeType: _this.$route.query.gradeType,
        courseType: _this.$route.query.courseType,
        chapterId: _this.$route.query.chapterId,
      },
      teacherSignList: [],
      tableUrl: getCourseListUrl,
      headers: [
        {
          prop: "gradeType",
          label: "年级分类",
          formatter: (row, prop) => gradeListMap[row.gradeType],
        },
        {
          prop: "courseType",
          label: "科目分类",
          formatter: (row, prop) => classesMap[row.courseType],
        },
        {
          prop: "chapterTitle",
          label: "章节名称",
        },
        {
          prop: "imageUrl",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "courseTitle",
          label: "课程名称",
        },
        {
          prop: "teacher",
          label: "老师",
        },
        {
          prop: "school",
          label: "学校",
        },
        {
          prop: "viewCount",
          label: "浏览量",
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.$setBreadList("课程管理");
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          courseId: item.id,
        };
        this.$axios
          .post(`${deleteCourseUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      let _this = this;
      this.$router.push({
        name: "courseForm",
        query: {
          gradeType: _this.$route.query.gradeType,
          courseType: _this.$route.query.courseType,
          chapterId: _this.$route.query.chapterId,
        },
      });
    },
    toEdit(row) {
      let _this = this;
      this.$router.push({
        name: "courseForm",
        query: {
          id: row.id,
          gradeType: _this.$route.query.gradeType,
          courseType: _this.$route.query.courseType,
          chapterId: _this.$route.query.chapterId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.courseList {
  box-sizing: border-box;
  height: 100%;
}
</style>
